export const InnovativeProdDevItems = [
  
  {
    srno: 1,
    productID: "4003",
    rLDProduct_Dev: "505(b)2 Concept",
    productName: "Melatonin Oral Solution (3 mg / ml)",
    productDevStatus: "Done",
    ipStatus: "Done",
    labStability: "Done",
    poc: "Done",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 2,
    productID: "4004",
    rLDProduct_Dev: "505(b)2 Concept",
    productName: "Melatonin Injection",
    productDevStatus: "Done",
    ipStatus: "Done",
    labStability: "Done",
    poc: "Done",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 3,
    productID: "RIB03033",
    rLDProduct_Dev: "DisolVR",
    productName: "RIB03033",
    productDevStatus: "Done",
    ipStatus: "Done",
    labStability: "",
    poc: "Pending",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 4,
    productID: "RIB04066",
    rLDProduct_Dev: "EzeGest",
    productName: "RIB04066",
    productDevStatus: "Done",
    ipStatus: "Done",
    labStability: "",
    poc: "Pending",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 5,
    productID: "RIB07073",
    rLDProduct_Dev: "RapiSol",
    productName: "RIB07073",
    productDevStatus: "Pending",
    ipStatus: "Done",
    labStability: "",
    poc: "Pending",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 6,
    productID: "RIB03061",
    rLDProduct_Dev: "DisolVR",
    productName: "RIB03061",
    productDevStatus: "Done",
    ipStatus: "Done",
    labStability: "",
    poc: "Pending",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 7,
    productID: "RIB07048",
    rLDProduct_Dev: "RapiSol",
    productName: "RIB07048",
    productDevStatus: "Pending",
    ipStatus: "Done",
    labStability: "",
    poc: "Pending",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 8,
    productID: "RIB07007",
    rLDProduct_Dev: "RapiSol",
    productName: "RIB07007",
    productDevStatus: "Pending",
    ipStatus: "Done",
    labStability: "",
    poc: "Pending",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 9,
    productID: "RIB07003",
    rLDProduct_Dev: "RapiSol",
    productName: "RIB07003",
    productDevStatus: "Pending",
    ipStatus: "Done",
    labStability: "",
    poc: "Pending",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 10,
    productID: "RIB07056",
    rLDProduct_Dev: "RapiSol",
    productName: "RIB07056",
    productDevStatus: "Done",
    ipStatus: "Done",
    labStability: "Done",
    poc: "Done",
    licensing: "",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 11,
    productID: "RIB04049",
    rLDProduct_Dev: "EzeGest",
    productName: "RIB04049",
    productDevStatus: "Pending",
    ipStatus: "Done",
    labStability: "",
    poc: "Pending",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 12,
    productID: "RIB03049",
    rLDProduct_Dev: "DisolVR",
    productName: "RIB03049",
    productDevStatus: "Pending",
    ipStatus: "Done",
    labStability: "",
    poc: "Pending",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 13,
    productID: "RIB04009",
    rLDProduct_Dev: "EzeGest",
    productName: "RIB04009",
    productDevStatus: "Pending",
    ipStatus: "Done",
    labStability: "",
    poc: "Pending",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 14,
    productID: "RIB05050",
    rLDProduct_Dev: "Sol2Gel",
    productName: "RIB05050",
    productDevStatus: "Pending",
    ipStatus: "Done",
    labStability: "",
    poc: "Pending",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 15,
    productID: "RIB04033",
    rLDProduct_Dev: "EzeGest",
    productName: "RIB04033",
    productDevStatus: "Done",
    ipStatus: "Done",
    labStability: "",
    poc: "Done",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 16,
    productID: "RIB06010",
    rLDProduct_Dev: "RiCream",
    productName: "RIB06010",
    productDevStatus: "Done",
    ipStatus: "Done",
    labStability: "",
    poc: "Done",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 17,
    productID: "RIB01068",
    rLDProduct_Dev: "CapMelt",
    productName: "RIB01068",
    productDevStatus: "Pending",
    ipStatus: "Pending",
    labStability: "Pending",
    poc: "Pending",
    licensing: "Pending",
    remark: "Development ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
];
